import { Mapper } from '@utils/Mapper';

import type { AnnouncementDTO } from '../dtos/AnnouncementDTO';
import type { Announcement } from '../models/Announcement';

export class AnnouncementDTOMapper extends Mapper<AnnouncementDTO, Announcement> {
	from(input: AnnouncementDTO): Announcement {
		return {
			id: input.id,
			title: input.title,
			shortDescription: input.shortDescription,
			description: input.description,
			scheduleTime: input.scheduleTime,
			videoUrl: input.videoUrl,
			image1: input.image1,
			image2: input.image2,
			allowRegistration: input.allowRegistration,
			contactEmail: input.contactEmail,
			contactPerson: input.contactPerson,
			location: input.location,
			active: input.active,
		};
	}
}
