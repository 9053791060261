import Announcement from '@pages/announcement/Announcement';
import CreateAnnouncement from '@pages/announcement/CreateAnnouncement';
import UpdateAnnouncement from '@pages/announcement/UpdateAnnouncement';
import React from 'react';

import PathConstants from './pathConstants';

const CreateStudent = React.lazy(async () => await import('../pages/students/CreateStudent'));
const Students = React.lazy(async () => await import('../pages/students/Students'));

const routes = [
	{ path: PathConstants.HOME, element: <Announcement />, exact: true },
	{ path: PathConstants.STUDENTS, element: <Students />, exact: true },
	{ path: PathConstants.CREATE_STUDENT, element: <CreateStudent /> },
	{ path: PathConstants.ANNOUNCEMENTS, element: <Announcement />, exact: true },
	{ path: PathConstants.CREATE_ANNOUNCEMENT, element: <CreateAnnouncement />, exact: true },
	{ path: PathConstants.UPDATE_ANNOUNCEMENT, element: <UpdateAnnouncement />, exact: true },
];

export default routes;
