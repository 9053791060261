import './index.css';

import { Authenticator } from '@aws-amplify/ui-react';
import { setupStore } from '@infra/redux/store';
import { ThemeProvider } from '@material-tailwind/react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
	<Provider store={setupStore()}>
		<ThemeProvider>
			<Authenticator>
				<App />
			</Authenticator>
		</ThemeProvider>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
