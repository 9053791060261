const PathConstants = {
	HOME: '/',
	STUDENTS: '/students',
	CREATE_STUDENT: '/students/create',
	ANNOUNCEMENTS: '/announcements',
	CREATE_ANNOUNCEMENT: '/announcements/create',
	UPDATE_ANNOUNCEMENT: '/announcements/update/:id',
};

export default PathConstants;
