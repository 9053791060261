import { useAppSelector } from '@infra/redux/hook';
import { Typography } from '@material-tailwind/react';
import { AnnouncementForm } from '@modules/websiteadmin/components/announcement';
import type { AnnouncementFormData } from '@modules/websiteadmin/redux/announcementSlice';
import {
	fetchAnnouncementById,
	getSelectedAnnouncement,
	selectedAnnouncement,
	updateAnnouncement,
} from '@modules/websiteadmin/redux/announcementSlice';
import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

export default function UpdateAnnouncement() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const announcementId = useParams().id;

	const announcement = useAppSelector(selectedAnnouncement);

	const handleSubmit: SubmitHandler<AnnouncementFormData> = (data: AnnouncementFormData) => {
		if (announcementId == null) {
			return;
		}

		const saveData = {
			id: announcementId,
			announcement: data,
		};

		dispatch(updateAnnouncement(saveData));
		navigate(-1);
	};

	useEffect(() => {
		if (announcement == null && announcementId != null) {
			dispatch(fetchAnnouncementById(announcementId));
		}
		if (announcementId != null) {
			dispatch(getSelectedAnnouncement(announcementId));
		}
	}, [announcementId]);

	return (
		<div>
			<Typography variant="h3" className="pb-3">
				Update Announcement
			</Typography>
			<AnnouncementForm
				shortDescValue={announcement?.shortDescription ?? ''}
				descValue={announcement?.description ?? ''}
				titleValue={announcement?.title ?? ''}
				videoUrlValue={announcement?.videoUrl ?? ''}
				contactEmailValue={announcement?.contactEmail ?? ''}
				image1Value={announcement?.image1 ?? ''}
				contactPersonValue={announcement?.contactPerson ?? ''}
				onSubmit={handleSubmit}
			/>
		</div>
	);
}
